import React from "react";
import Logo from "../assets/logo5_noBG.png";

const Masthead = (props) => {
  return (
    <header className="masthead">
      <div className="container">
        <div className="masthead-subheading">
          <img className="card-img-top" src={Logo} alt="Main-masthead" />
        </div>
        <div className="masthead-heading text-uppercase">
          {props.lang ? "Yours is just to ask" : "Ваш - просто спросить"}
        </div>
        <a className="btn btn-primary btn-xl text-uppercase" href="#services">
          {props.lang ? "Tell Me More" : "Расскажи мне больше"}
        </a>
      </div>
    </header>
  );
};

export default Masthead;
