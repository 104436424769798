import React from "react";
import Image from "../assets/new map ready.png";

const Map = (props) => {
  return (
    <div className="collapse card card-body" id="image_map">
      <img src={Image} alt="Map" useMap="#image-map" className="img-fluid" />
      <a
        style={{ top: "5%", left: "21.8%" }}
        href="https://www.google.com/maps/place/Aerodrom+Maribor+P.O./@46.480659,15.6782745,14.86z/data=!4m5!3m4!1s0x476f7bcbb2d32fed:0x49b93544fd024a33!8m2!3d46.4789439!4d15.6838198?hl=en"
        title="Maribor Airport"
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{ __html: "M" }}
      ></a>
      <a
        style={{ top: "13%", left: "12.5%" }}
        href="https://www.google.com/maps/place/Ljubljana+Jo%C5%BEe+Pu%C4%8Dnik+Airport/@46.2195313,14.4399495,13.15z/data=!4m5!3m4!1s0x477ab5c6f416db4b:0x24c4600b081571a5!8m2!3d46.2259433!4d14.4559143?hl=en"
        title="Ljubljana Airport"
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{ __html: "Lj" }}
      ></a>
      <a
        style={{ top: "17%", left: "24.5%" }}
        href="https://www.google.com/maps/place/Franjo+Tu%C4%91man+Airport+Zagreb/@45.7360782,16.0622568,13.5z/data=!4m5!3m4!1s0x47667ee85c7fab29:0x9ef60b3c28e288f6!8m2!3d45.7407504!4d16.0674366?hl=en"
        title="Zagreb Airport"
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{ __html: "Z" }}
      ></a>
      <a
        style={{ top: "32%", left: "7%" }}
        href="https://www.google.com/maps/place/Pula+Airport/@44.883117,13.8685225,13z/data=!4m5!3m4!1s0x47632d7b375f9f8f:0x406c24dfa49fc5b4!8m2!3d44.8995099!4d13.9233752?hl=en"
        title="Pula Airport"
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{ __html: "P" }}
      ></a>
      <a
        style={{ top: "33%", left: "61.3%" }}
        href="https://www.google.com/maps/place/%D0%9C%D0%B5%D1%92%D1%83%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%B8+%D0%B0%D0%B5%D1%80%D0%BE%D0%B4%D1%80%D0%BE%D0%BC+%E2%80%9E%D0%9D%D0%B8%D0%BA%D0%BE%D0%BB%D0%B0+%D0%A2%D0%B5%D1%81%D0%BB%D0%B0%E2%80%9C+%D0%91%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D0%B4/@44.7895899,20.3177738,13z/data=!4m5!3m4!1s0x475a688a5214cf59:0xc3184c4bc7f300f9!8m2!3d44.8202985!4d20.2931408?hl=en"
        title="Belgrade Airport"
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{ __html: "B" }}
      ></a>
      <a
        style={{ top: "44%", left: "18.5%" }}
        href="https://www.google.com/maps/place/Zadar+Airport/@44.0981249,15.2898283,13.5z/data=!4m5!3m4!1s0x4761e59269455823:0xd8e219946423a70a!8m2!3d44.0964983!4d15.3533664?hl=en"
        title="Zadar Airport"
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{ __html: "Z" }}
      ></a>
      <a
        style={{ top: "48%", left: "44.5%" }}
        href="https://www.google.com/maps/place/Sarajevo+International+Airport/@43.8321412,18.3197094,14z/data=!4m5!3m4!1s0x4758c9f5798e6ed3:0x4418069785b9985a!8m2!3d43.8262503!4d18.3368636?hl=en"
        title="Sarajevo Airport"
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{ __html: "SA" }}
      ></a>
      <a
        style={{ top: "70.5%", left: "47%" }}
        href="https://www.google.com/maps/place/Tivat+Airport/@42.4258734,18.710489,14.06z/data=!4m12!1m6!3m5!1s0x134de96c4166ce8f:0x9bb6c7b94a148fad!2sPodgorica+Airport!8m2!3d42.3678579!4d19.247673!3m4!1s0x134c321755e086f5:0x702479d9cf9181c8!8m2!3d42.4049666!4d18.7235536?hl=en"
        title="Tivat Airport"
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{ __html: "TI" }}
      ></a>
      <a
        style={{ top: "70.5%", left: "51.5%" }}
        href="https://www.google.com/maps/place/Podgorica+Airport/@42.4444382,19.217485,12z/data=!4m5!3m4!1s0x134de96c4166ce8f:0x9bb6c7b94a148fad!8m2!3d42.3678579!4d19.247673?hl=en"
        title="Podgorica Airport"
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{ __html: "P" }}
      ></a>
      <a
        style={{ top: "77%", left: "69.5%" }}
        href="https://www.google.com/maps/place/Skopje+International+Airport/@41.9611918,21.612869,13.42z/data=!3m1!5s0x135439a5a6241ac9:0x444a74e7bd8e0df0!4m5!3m4!1s0x135439a630686ec1:0xc481f7a5487fb0a7!8m2!3d41.963161!4d21.6223532?hl=en"
        title="Skopje Airport"
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{ __html: "SK" }}
      ></a>
      <a
        style={{ top: "88%", left: "56%" }}
        href="https://www.google.com/maps/place/Tirana+International+Airport/@41.4104009,19.7160308,13.57z/data=!4m5!3m4!1s0x13502d0712c5607f:0x5254c1f62d5286db!8m2!3d41.4191093!4d19.7133825?hl=en"
        title="Tirana Airport"
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{ __html: "TA" }}
      ></a>
    </div>
  );
};

export default Map;

// data-toggle="collapse"
// href="#image_map"
// role="button"
// aria-expanded="false"
// aria-controls="image_map"
