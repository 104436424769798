import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

const Services = (props) => {
  const [key, setKey] = useState("home");

  return (
    <section className="page-section" id="services">
      <h1 id="h1">{props.lang ? "How it works" : "Как это работает"}</h1>
      <p id="paragraph">
        {props.lang
          ? "Speed up and facilitate your way through the airport."
          : "Ускорьте и упростите свой путь через аэропорт."}
      </p>
      <div id="service_container">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          {props.lang ? (
            <Tab eventKey="home" title="ARRIVAL MEET AND GREET">
              <ul>
                <li>
                  Our dedicated greeter will meet passengers at the arrival gate
                  or at the entrance of the terminal. (Depending on where the
                  aircraft is parked) with an appropriate welcome board.
                </li>

                <li>
                  Our greeter will assist your passengers to expedite passing
                  through all airport formalities (passport control, security,
                  customs…)
                </li>

                <li>
                  Your esteemed passengers will be escorted to their
                  transportation.
                </li>
              </ul>
            </Tab>
          ) : (
            <Tab eventKey="home" title="ПРИБЫТИЕ ВСТРЕЧА И ПРИВЕТСТВИЕ">
              <ul>
                <li>
                  Наш преданный встречающий встретит пассажиров у выхода на
                  посадку или у входа в терминал. (В зависимости от того, где
                  припаркован самолет) с соответствующей табличкой приветствия.
                </li>

                <li>
                  Наш встречающий поможет вашим пассажирам ускорить прохождение
                  всех формальностей в аэропорту (паспортный контроль,
                  безопасность, таможня ...)
                </li>

                <li>
                  Ваши уважаемые пассажиры будут сопровождены к месту их
                  перевозки.
                </li>
              </ul>
            </Tab>
          )}
          {props.lang ? (
            <Tab eventKey="profile" title="DEPARTURE MEET AND GREET">
              <ul>
                <li>
                  Our dedicated greeter will meet your passengers at an agreed
                  meeting spot and assist with the check-in process.
                </li>

                <li>
                  Passengers will be escorted and expedited through security
                  check and passport control.
                </li>

                <li>
                  Our greeter will escort passengers to one of the airport
                  lounges / if they have access.
                </li>

                <li>
                  Our greeter will escort passengers to the departure gate.
                </li>
              </ul>
            </Tab>
          ) : (
            <Tab eventKey="profile" title="ВЫЕЗД ВСТРЕЧАЙТЕ И ПРИВЕТСТВУЙТЕ">
              <ul>
                <li>
                  Наш специальный представитель встретит ваших пассажиров в
                  согласованном месте встречи и поможет в процессе регистрации.
                </li>

                <li>
                  Пассажиров будут сопровождать и проводить досмотр через службу
                  безопасности и паспортный контроль.
                </li>

                <li>
                  Наш встречающий сопроводит пассажиров в один из залов ожидания
                  аэропорта, если у них есть доступ.
                </li>

                <li>Встречающий сопроводит пассажиров к выходу на посадку.</li>
              </ul>
            </Tab>
          )}
          {props.lang ? (
            <Tab eventKey="contact" title="TRANSIT MEET AND GREET">
              <ul>
                <li>
                  Our dedicated greeter will meet passengers upon arrival at the
                  arrival gate or entrance of the terminal.
                </li>

                <li>
                  In case passport control is required, our greeter will assist
                  passengers to expedite this procedure.
                </li>

                <li>
                  All airport formalities will be done swiftly, if required.
                </li>

                <li>Passengers will be escorted to the departure gate.</li>
              </ul>
            </Tab>
          ) : (
            <Tab eventKey="contact" title="ТРАНЗИТ ВСТРЕЧАЙТЕ И ПРИВЕТСТВУЙТЕ">
              <ul>
                <li>
                  Наш преданный встречающий встретит пассажиров по прибытии у
                  выхода на посадку или у входа в терминал.
                </li>

                <li>
                  В случае необходимости паспортного контроля наш встречающий
                  поможет пассажирам ускорить эту процедуру.
                </li>

                <li>
                  При необходимости все формальности в аэропорту будут выполнены
                  в кратчайшие сроки.
                </li>

                <li>Пассажиров проводят к выходу на посадку.</li>
              </ul>
            </Tab>
          )}
          {props.lang ? (
            <Tab eventKey="contact2" title="PORTER SERVICE">
              <ul>
                <li>
                  Our dedicated staff will assist your passengers with carrying
                  luggage through all formalities, depending if service is
                  required upon arrival, departure or through transit.
                </li>
              </ul>
            </Tab>
          ) : (
            <Tab eventKey="contact2" title="ПОРТЕР СЕРВИС">
              <ul>
                <li>
                  Наши преданные своему делу сотрудники помогут вашим пассажирам
                  с багажом пройти все формальности, в зависимости от того,
                  требуется ли услуга по прибытии, отбытии или транзитом.
                </li>
              </ul>
            </Tab>
          )}
        </Tabs>
      </div>
    </section>
  );
};

export default Services;
