import React from "react";

const Contact = (props) => {
  const sendMail = async () => {
    let name = document.getElementById("name").value;
    let email = document.getElementById("email").value;
    let phone = document.getElementById("phone").value;
    let message = document.getElementById("message").value;
    let emailTest = false;

    let mailformat = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";

    if (email.match(mailformat)) {
      emailTest = true;
    }

    if (
      name !== "" &&
      email !== "" &&
      emailTest &&
      phone !== "" &&
      message !== ""
    ) {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/access",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({ email, message, phone, name }),
        }
      );
      const resData = await response.json();
      if (resData.status === "success") {
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
        document.getElementById("phone").value = "";
        document.getElementById("message").value = "";
        document
          .getElementById("submitSuccessMessage")
          .classList.remove("d-none");
        setTimeout(function () {
          document
            .getElementById("submitSuccessMessage")
            .classList.add("d-none");
        }, 3000);
      } else if (resData.status === "fail") {
        alert("Message failed to send.");
      }
    } else {
      console.log("Problem with email sending!");
    }
  };
  const checkName = () => {
    let name = document.getElementById("name");
    if (name.value === "") {
      name.classList.add("is-invalid");
    } else {
      name.classList.remove("is-invalid");
    }
  };
  const checkEmail = () => {
    let name = document.getElementById("email");
    if (name.value === "") {
      name.classList.add("is-invalid");
    } else {
      name.classList.remove("is-invalid");
    }
  };
  const checkPhone = () => {
    let name = document.getElementById("phone");
    if (name.value === "") {
      name.classList.add("is-invalid");
    } else {
      name.classList.remove("is-invalid");
    }
  };
  const checkText = () => {
    let name = document.getElementById("message");
    if (name.value === "") {
      name.classList.add("is-invalid");
    } else {
      name.classList.remove("is-invalid");
    }
  };

  return (
    <section className="page-section" id="contact">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">
            {props.lang ? "Contact Us" : "Связаться с нами"}
          </h2>
          <h3 className="section-subheading text-muted">
            {props.lang ? "Yours is just to ask." : "Ваш - просто спросить."}
          </h3>
        </div>

        <form id="contactForm" onSubmit={(e) => e.preventDefault()}>
          <div className="row align-items-stretch mb-5">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className="form-control"
                  id="name"
                  type="text"
                  placeholder="Your Name *"
                  data-sb-validations="required"
                  onBlur={checkName}
                />
                <div
                  className="invalid-feedback"
                  data-sb-feedback="name:required"
                >
                  A name is required.
                </div>
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  id="email"
                  type="email"
                  placeholder="Your Email *"
                  data-sb-validations="required,email"
                  onBlur={checkEmail}
                />
                <div
                  className="invalid-feedback"
                  data-sb-feedback="email:required"
                >
                  An email is required.
                </div>
                <div
                  className="invalid-feedback"
                  data-sb-feedback="email:email"
                >
                  Email is not valid.
                </div>
              </div>
              <div className="form-group mb-md-0">
                <input
                  className="form-control"
                  id="phone"
                  type="tel"
                  placeholder="Your Phone *"
                  data-sb-validations="required"
                  onBlur={checkPhone}
                />
                <div
                  className="invalid-feedback"
                  data-sb-feedback="phone:required"
                >
                  A phone number is required.
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group form-group-textarea mb-md-0">
                <textarea
                  className="form-control"
                  id="message"
                  placeholder="Your Message *"
                  data-sb-validations="required"
                  onBlur={checkText}
                ></textarea>
                <div
                  className="invalid-feedback"
                  data-sb-feedback="message:required"
                >
                  A message is required.
                </div>
              </div>
            </div>
          </div>

          <div className="d-none" id="submitSuccessMessage">
            <div className="text-center text-white mb-3">
              <div className="fw-bolder">Form submission successful!</div>
            </div>
          </div>

          <div className="d-none" id="submitErrorMessage">
            <div className="text-center text-danger mb-3">
              Error sending message!
            </div>
          </div>

          <div className="text-center">
            <button
              className="btn btn-primary btn-xl text-uppercase"
              onClick={sendMail}
              id="submitButton"
              type="submit"
            >
              {props.lang ? "Send Message" : "Отправить Cообщение"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
