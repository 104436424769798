import React from "react";
import Map from "./map.js";

const Network = (props) => {
  const openMap = () => {
    let val = document.getElementsByClassName("form-select")[0].value;
    switch (val) {
      case "1":
        window.open(
          "https://www.google.com/maps/place/%D0%9C%D0%B5%D1%92%D1%83%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%B8+%D0%B0%D0%B5%D1%80%D0%BE%D0%B4%D1%80%D0%BE%D0%BC+%E2%80%9E%D0%9D%D0%B8%D0%BA%D0%BE%D0%BB%D0%B0+%D0%A2%D0%B5%D1%81%D0%BB%D0%B0%E2%80%9C+%D0%91%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D0%B4/@44.7895899,20.3177738,13z/data=!4m5!3m4!1s0x475a688a5214cf59:0xc3184c4bc7f300f9!8m2!3d44.8202985!4d20.2931408?hl=en"
        );
        break;
      case "2":
        window.open(
          "https://www.google.com/maps/place/Podgorica+Airport/@42.4444382,19.217485,12z/data=!4m5!3m4!1s0x134de96c4166ce8f:0x9bb6c7b94a148fad!8m2!3d42.3678579!4d19.247673?hl=en"
        );
        break;
      case "3":
        window.open(
          "https://www.google.com/maps/place/Tivat+Airport/@42.4258734,18.710489,14.06z/data=!4m12!1m6!3m5!1s0x134de96c4166ce8f:0x9bb6c7b94a148fad!2sPodgorica+Airport!8m2!3d42.3678579!4d19.247673!3m4!1s0x134c321755e086f5:0x702479d9cf9181c8!8m2!3d42.4049666!4d18.7235536?hl=en"
        );
        break;
      case "4":
        window.open(
          "https://www.google.com/maps/place/Franjo+Tu%C4%91man+Airport+Zagreb/@45.7360782,16.0622568,13.5z/data=!4m5!3m4!1s0x47667ee85c7fab29:0x9ef60b3c28e288f6!8m2!3d45.7407504!4d16.0674366?hl=en"
        );
        break;
      case "5":
        window.open(
          "https://www.google.com/maps/place/Zadar+Airport/@44.0981249,15.2898283,13.5z/data=!4m5!3m4!1s0x4761e59269455823:0xd8e219946423a70a!8m2!3d44.0964983!4d15.3533664?hl=en"
        );
        break;
      case "6":
        window.open(
          "https://www.google.com/maps/place/Pula+Airport/@44.883117,13.8685225,13z/data=!4m5!3m4!1s0x47632d7b375f9f8f:0x406c24dfa49fc5b4!8m2!3d44.8995099!4d13.9233752?hl=en"
        );
        break;
      case "7":
        window.open(
          "https://www.google.com/maps/place/Tirana+International+Airport/@41.4104009,19.7160308,13.57z/data=!4m5!3m4!1s0x13502d0712c5607f:0x5254c1f62d5286db!8m2!3d41.4191093!4d19.7133825?hl=en"
        );
        break;
      case "8":
        window.open(
          "https://www.google.com/maps/place/Skopje+International+Airport/@41.9611918,21.612869,13.42z/data=!3m1!5s0x135439a5a6241ac9:0x444a74e7bd8e0df0!4m5!3m4!1s0x135439a630686ec1:0xc481f7a5487fb0a7!8m2!3d41.963161!4d21.6223532?hl=en"
        );
        break;
      case "9":
        window.open(
          "https://www.google.com/maps/place/Ljubljana+Jo%C5%BEe+Pu%C4%8Dnik+Airport/@46.2195313,14.4399495,13.15z/data=!4m5!3m4!1s0x477ab5c6f416db4b:0x24c4600b081571a5!8m2!3d46.2259433!4d14.4559143?hl=en"
        );
        break;
      case "10":
        window.open(
          "https://www.google.com/maps/place/Aerodrom+Maribor+P.O./@46.480659,15.6782745,14.86z/data=!4m5!3m4!1s0x476f7bcbb2d32fed:0x49b93544fd024a33!8m2!3d46.4789439!4d15.6838198?hl=en"
        );
        break;
      case "11":
        window.open(
          "https://www.google.com/maps/place/Sarajevo+International+Airport/@43.8321412,18.3197094,14z/data=!4m5!3m4!1s0x4758c9f5798e6ed3:0x4418069785b9985a!8m2!3d43.8262503!4d18.3368636?hl=en"
        );
        break;
      default:
        console.log("NO AIRPORT FOUND!");
    }
  };

  return (
    <section className="page-section bg-light" id="network">
      <div className="container">
        <div className="text-center">
          <h1 className="section-heading">
            {props.lang ? "Our network" : "Наша сеть"}
          </h1>
          <h5>
            {props.lang
              ? "On our network rest assured that you'll get the best service possible."
              : "В нашей сети будьте уверены, что вы получите лучший сервис возможный."}
          </h5>
          <svg
            stroke="currentColor"
            fillRule="currentColor"
            strokeWidth="0"
            viewBox="0 0 576 512"
            height="3em"
            width="3em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M480 192H365.71L260.61 8.06A16.014 16.014 0 0 0 246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z"></path>
          </svg>
          <h1>11</h1>
          <h5>{props.lang ? "AIRPORTS" : "АЭРОПОРТЫ"}</h5>
          <select
            style={{ width: "40%" }}
            className="form-select form-select-sm mb-3"
            aria-label=".form-select-sm example"
            onChange={openMap}
          >
            <option>
              {props.lang
                ? "Cities where you are safe with us..."
                : "Города, в которых с нами ты в безопасности..."}
            </option>
            <option value="1">1. Belgrade, Serbia</option>
            <option value="2">2. Podgorica, Montenegro</option>
            <option value="3">3. Tivat, Montenegro</option>
            <option value="4">4. Zagreb, Croatia</option>
            <option value="5">5. Zadar, Croatia</option>
            <option value="6">6. Pula, Croatia</option>
            <option value="7">7. Tirana, Albania</option>
            <option value="8">8. Skopje, Republic of Macedonia</option>
            <option value="9">9. Ljubljana, Slovenia</option>
            <option value="10">10. Maribor, Slovenia</option>
            <option value="11">11. Sarajevo, Bosnia and Herzegovina</option>
          </select>
          <h2
            className="section-subheading text-muted"
            data-toggle="collapse"
            href="#image_map"
            role="button"
            aria-expanded="false"
            aria-controls="image_map"
          >
            {props.lang
              ? "Click here to see full network."
              : "Щелкните здесь, чтобы увидеть всю сеть."}
          </h2>
        </div>
      </div>
      <Map />
    </section>
  );
};

export default Network;
