import React, { useState } from "react";
import "./App.css";
import Navigation from "./components/navigation";
import Masthead from "./components/masthead";
import Services from "./components/services";
import Network from "./components/network";
import Contact from "./components/contact_form";
import Footer from "./components/footer";
// import bootstrap from "bootstrap";

function App() {
  const [lang, setLang] = useState(true);
  window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle.js");
  window.addEventListener("DOMContentLoaded", (event) => {
    // Navbar shrink function
    var navbarShrink = function () {
      const navbarCollapsible = document.body.querySelector("#mainNav");
      if (!navbarCollapsible) {
        return;
      }
      if (window.scrollY === 0) {
        navbarCollapsible.classList.remove("navbar-shrink");
      } else {
        navbarCollapsible.classList.add("navbar-shrink");
      }
    };

    // Shrink the navbar
    navbarShrink();

    // Shrink the navbar when page is scrolled
    document.addEventListener("scroll", navbarShrink);

    // Activate Bootstrap scrollspy on the main nav element
    // const mainNav = document.body.querySelector("#mainNav");
    // if (mainNav) {
    //   new bootstrap.ScrollSpy(document.body, {
    //     target: "#mainNav",
    //     offset: 74,
    //   });
    // }

    // Collapse responsive navbar when toggler is visible
    const navbarToggler = document.body.querySelector(".navbar-toggler");
    const responsiveNavItems = [].slice.call(
      document.querySelectorAll("#navbarResponsive .nav-link")
    );
    responsiveNavItems.map(function (responsiveNavItem) {
      responsiveNavItem.addEventListener("click", () => {
        if (window.getComputedStyle(navbarToggler).display !== "none") {
          navbarToggler.click();
        }
      });
    });
  });

  const toEnglish = () => {
    setLang(true);
  };
  const toRussian = () => {
    setLang(false);
  };

  return (
    <React.Fragment>
      <Navigation english={toEnglish} russian={toRussian} lang={lang} />
      <Masthead lang={lang} />
      <Services lang={lang} />
      <Network lang={lang} />
      <Contact lang={lang} />
      <Footer lang={lang} />
    </React.Fragment>
  );
}

export default App;
